import React from 'react';
// import { useState, useEffect } from 'react';
import parseParamters from '../componentLogic/parseParameters';
import styles from '../../styles/thankyou.scss';

export default function Thankyou({ children, event, redirect }) {
  // const [event, setEventName] = useState('');
  // const { event, redirect } = parseParamters();
  // console.log('thankyou');
  // console.log(event);
  if (redirect) {
    setTimeout(() => {
      window.location.href = redirect;
    }, 1500);
  }

  let eventMessage = '';
  if (event) eventMessage = ` for ${event}`;

  return (
    <div className="thankyou--wrapper">
      <span className="thankyou--title">{`Thank you for registering${eventMessage}!`}</span>
      <div className="thankyou--body">{children}</div>
    </div>
  );
}
