import React from 'react';
import Layout from '../components/CleanLayout';
import Thankyou from '../components/thankyou/Thankyou';

const METADATA = [
  {
    name: 'description',
    content: "Thank you for registering for Subtle Medical's RSNA Lunch and Learn",
  },
  {
    name: 'keywords',
    content: 'RSNA, lunch and learn, PET, MRI, radiology, AI, Subtle Medical',
  },
];

const index = () => (
  <Layout pageTitle="Thank You for Registering" metadata={METADATA}>
    <Thankyou>
      <span className="thankyou--subtext">
        You'll receive a confirmation email soon. If you can't find it, please check your spam
        folder.
      </span>
    </Thankyou>
  </Layout>
);

export default index;
